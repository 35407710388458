const TournamentUser = require("parse/TournamentUser");
const notificationActions = require("components/notification/NotificationActions");

function TournamentUserCupResultDic(maxTuserResultAmount, attendFinal, finalResult) {
    this.maxTuserResultAmount = maxTuserResultAmount;
    this.attendFinal = attendFinal // attending final is mandatory
    this.finalResult = finalResult; // drop archers not meeting cup result requirements
    this.attendeeAmount = {};
    this.groupedResults = [];
    this.primeGroup = [];
    this.secondGroup = null;
    this.roundSize = [];
    this.childTournamentsIDs = [];
    this.lastTournamentID = null;
    this.cupGroups = null;
    this.tUserPrimeGroupDic = {};
    this.lastLicNumber = null;
    this.lastLicCupGroup = null;
    this.licRegex = null;

    this.addCupSortTournamentUser = function (tournamentUser) {
        if ("0000" === tournamentUser.getLicenseNumber() || (this.licRegex != null && !tournamentUser.getLicenseNumber().match(this.licRegex))) {
            // invalid license found
            notificationActions.warning("Invalid license: " + tournamentUser.getLicenseNumber() + " -> " + tournamentUser.getFullName())
            return;
        }
        // remember LicNumber and sumUp to Max 4
        let primeGroupID = tournamentUser.getTournamentConfigBow().id + "#" + tournamentUser.getTournamentConfigAge().id;
        let tPrimeGroupUser = this.tUserPrimeGroupDic[primeGroupID];
        // if (tournamentUser.getLicenseNumber() == "10382") {
        //     console.log("Found ", tournamentUser.getFullName(), tournamentUser.getCupGroup(), tournamentUser.getStatus(), tournamentUser.getCupPoints(), this.lastLicCupGroup, tPrimeGroupUser)
        // }
        if (this.lastLicNumber != null && this.lastLicNumber === tournamentUser.getLicenseNumber()) {
            // handle same user
            if (this.cupGroups != null) {
                // special cup group handling
                if (tournamentUser.getCupGroup() == null) {
                    notificationActions.error("No cup group for user: " + tournamentUser.getFullName(), "CupResult")
                    return
                } else if (tPrimeGroupUser != null && this.lastLicCupGroup === tournamentUser.getLicenseNumber() +":" + tournamentUser.getCupGroup()) {
                    console.log("Found second result for " + this.lastLicCupGroup, tournamentUser.getFullName(), tournamentUser.getCupPoints())
                    return
                }
                this.lastLicCupGroup = tournamentUser.getLicenseNumber() + ":" + tournamentUser.getCupGroup()
            }
            if (tPrimeGroupUser) {
                // the same tournamentUser and bow class - add results if not maxResultAmount reached
                // if cupGroups are available - all points need to be gathered first (due to sorting of group before points)
                if (this.cupGroups != null || tPrimeGroupUser.counter < this.maxTuserResultAmount) {
                    // add result
                    tPrimeGroupUser.cupPoints += tournamentUser.getCupPoints();
                    tPrimeGroupUser.positions += tournamentUser.getResultPositionForCupSorting();
                    tPrimeGroupUser.sumPoints += tournamentUser.getSortableSumPoints()
                    tPrimeGroupUser.highestZoneCounter += tournamentUser.getHighestZoneCount();
                    tPrimeGroupUser.counter++;
                    tPrimeGroupUser.tournamentIDs.push(tournamentUser.getTournamentID().id)
                    //this.tUserPrimeGroupDic[primeGroupID] = tPrimeGroupUser;
                    // console.log("Added to result ", tPrimeGroupUser)
                } else {
                    console.log("Skip result for " + this.lastLicNumber, tournamentUser.id, tournamentUser.getFullName(), tournamentUser.getCupPoints())
                    // do not add to point result - but add to highest zone counter and attended tournament
                    tPrimeGroupUser.highestZoneCounter += tournamentUser.getHighestZoneCount();
                    tPrimeGroupUser.tournamentIDs.push(tournamentUser.getTournamentID().id)
                }
                if (this.cupGroups != null) {
                    // use cupGroups
                    for (let i = 0; i < this.cupGroups.length; i++) {
                        if (this.cupGroups[i] == tournamentUser.getCupGroup()) {
                            tPrimeGroupUser.allCupPoints[i] = tournamentUser.getCupPointString();
                            break;
                        }
                    }
                } else {
                    // add cup points to cupPoints array
                    for (let i = 0; i < this.childTournamentsIDs.length; i++) {
                        if (this.childTournamentsIDs[i] == tournamentUser.getTournamentID().id) {
                            tPrimeGroupUser.allCupPoints[i] = tournamentUser.getCupPointString();
                            break;
                        }
                    }
                }
            } else {
                // the same tournamentUser with other bow class
                this.tUserPrimeGroupDic[primeGroupID] = this.buildtUserCountObject(tournamentUser);
            }
            // console.log("tPrimeGroupUser finished", tPrimeGroupUser, this.tUserPrimeGroupDic[primeGroupID])
        } else {
            // handle other user
            if (Object.keys(this.tUserPrimeGroupDic).length > 0) {
                // has elements - other tournamentUser - add lastTournamentUser to DIC
                this.addLastTournamentUserToDic();
                // set the new one
                this.tUserPrimeGroupDic = {};
                this.lastLicNumber = tournamentUser.getLicenseNumber();
                this.lastLicCupGroup = tournamentUser.getLicenseNumber() +":" + tournamentUser.getCupGroup()
                this.tUserPrimeGroupDic[primeGroupID] = this.buildtUserCountObject(tournamentUser);
                // console.log("Other user - new", tPrimeGroupUser, this.tUserPrimeGroupDic[primeGroupID])
            } else {
                // first one - no prior tournamentUser
                this.lastLicNumber = tournamentUser.getLicenseNumber();
                this.lastLicCupGroup = tournamentUser.getLicenseNumber() +":" + tournamentUser.getCupGroup()
                this.tUserPrimeGroupDic[primeGroupID] = this.buildtUserCountObject(tournamentUser);
                // console.log("Other user - no prior", tPrimeGroupUser, this.tUserPrimeGroupDic[primeGroupID])
            }

        }
    };
    this.buildtUserCountObject = function(tournamentUser) {
        let cupPointsArray = [];
        // build array for cupPoints
        if (this.cupGroups != null) {
            // use cupGroups
            for (let i = 0; i < this.cupGroups.length; i++) {
                if (this.cupGroups[i] == tournamentUser.getCupGroup()) {
                    cupPointsArray.push(tournamentUser.getCupPointString())
                } else {
                    cupPointsArray.push("-x-")
                }
            }
        } else {
            // use childTournaments
            for (let i = 0; i < this.childTournamentsIDs.length; i++) {
                if (this.childTournamentsIDs[i] == tournamentUser.getTournamentID().id) {
                    cupPointsArray.push(tournamentUser.getCupPointString())
                } else {
                    cupPointsArray.push("-x-")
                }
            }
        }
        return {
            tUser: tournamentUser,
            licNumber: tournamentUser.getLicenseNumber(),
            counter: 1,
            tournamentIDs: [tournamentUser.getTournamentID().id],
            highestZoneCounter: tournamentUser.getHighestZoneCount(),
            cupPoints: tournamentUser.getCupPoints(),
            allCupPoints: cupPointsArray,
            positions: tournamentUser.getResultPositionForCupSorting(),
            sumPoints: tournamentUser.getSortableSumPoints()
        };
    };
    this.addLastTournamentUserToDic = function() {
        // add lastTournamentUser to DIC
        Object.keys(this.tUserPrimeGroupDic).map(primeGroupID => {
            let tPGUser = this.tUserPrimeGroupDic[primeGroupID];
            if (this.cupGroups != null && tPGUser.counter > this.maxTuserResultAmount) {
                // has more results as need - due to group sorting - points and counter needs to be recalculated
                tPGUser.cupPoints=0
                tPGUser.counter=0
                let cpEntries = Object.values(tPGUser.allCupPoints);
                cpEntries.sort(function(a, b){return b - a});
                for (let i = 0; i < this.maxTuserResultAmount; i++) {
                    tPGUser.cupPoints+=parseInt(cpEntries[i]);
                    tPGUser.counter++;
                }
                console.log("ReCalc cupResult for " + tPGUser.tUser.getLicenseNumber(), tPGUser.tUser.id, tPGUser.tUser.getFullName(), tPGUser)
            }
            if (this.checkCupResultRequirements(tPGUser)) {
                let tUser = tPGUser.tUser;
                tUser.setCupPoints(tPGUser.cupPoints);
                tUser.setKillValue(tPGUser.positions);
                tUser.setSumPoints(tPGUser.sumPoints)
                tUser.setAllCupPoints(tPGUser.allCupPoints);
                tUser.setFinalKillValue(tPGUser.highestZoneCounter);
                this.addSortTournamentUser(tUser);
            }
        });
    };
    this.checkCupResultRequirements = function (tPGUser) {
        if (this.finalResult && tPGUser.counter < this.maxTuserResultAmount) {
            console.log("To less result (" + tPGUser.counter + "): " + tPGUser.tUser.getLicenseNumber(), tPGUser.tUser.id, tPGUser.tUser.getFullName(), tPGUser)
            return false;
        } else if (this.finalResult && this.attendFinal && tPGUser.tournamentIDs !=null ) {
            // check attended last tournament
            if (!tPGUser.tournamentIDs.includes(this.lastTournamentID)) {
                console.log("Not in last tournament (" + this.lastTournamentID + "): " + tPGUser.tUser.getLicenseNumber(), tPGUser.tUser.id, tPGUser.tUser.getFullName(), tPGUser)
                return false;
            }
        }
        // console.log("Accepted: " + tPGUser.tUser.getLicenseNumber(), tPGUser.tUser.id, tPGUser.tUser.getFullName(), tPGUser)
        return true;
    }
    this.addSortTournamentUser = function (tournamentUser) {
        let bowGroup = tournamentUser.getTournamentConfigBow();
        let ageGroup = tournamentUser.getTournamentConfigAge();
        let tuSex = tournamentUser.getSex();
        if (ageGroup.hasNoSexSplit()) {
            tuSex = "x";
        }
        let key = this.generateDicKey(bowGroup, ageGroup, tuSex);
        // amounts
        this.updateGroupAmountCounter(this.generateDicKey(bowGroup, ageGroup, "_"));
        this.updateGroupAmountCounter(bowGroup.id);
        this.updateGroupAmountCounter(ageGroup.id);
        // check for bow
        this.checkForGroup(this.primeGroup, bowGroup);
        this.addUserToGroup(this.groupedResults, key, tournamentUser);
    };
    this.getTournamentPrimeGroup = function() {
        return this.primeGroup
    };
    this.getTournamentSecondGroup = function() {
        return this.secondGroup
    };
    this.setTournamentSecondGroup = function(configGroups) {
        this.secondGroup = configGroups;
    };
    this.setChildTournamentsIDs  = function (tournamentIds) {
        this.childTournamentsIDs = tournamentIds;
        if (tournamentIds != null && tournamentIds.length > 0) {
            this.lastTournamentID = tournamentIds[tournamentIds.length - 1];
        }
    };
    this.setLicenseRegex = function (regex) {
        this.licRegex = regex;
    };
    this.setCupGroups = function (cupGroups) {
        this.cupGroups = cupGroups
    };
    this.getAttendeeAmount = function (key) {
        return this.attendeeAmount[key];
    };
    this.generateDicKey = function(primeConfig, secConfig, tuSex) {
        let sec = "";
        if (secConfig != null) {
            sec = secConfig.id;
        }
        return primeConfig.id + "z" + tuSex + "z" + sec;
    };
    this.updateGroupAmountCounter = function(key) {
        let counter = this.attendeeAmount[key];
        if (counter == null) {
            counter = 0;
        }
        counter++;
        this.attendeeAmount[key] = counter;
    };
    this.getList = function(key) {
        let groupedTUList = this.groupedResults[key];
        if (groupedTUList && groupedTUList.length > 0) {
            return groupedTUList;
        } else {
            return null;
        }

    };
    this.validateGroupedList = function(callbackTUserPair) {
        Object.keys(this.groupedResults).map(key => {
            let nameDic = {};
            let groupedTUList = this.groupedResults[key];
            // try to find same archers
            groupedTUList.map(tUser => {
                let userKey = tUser.getName().toLowerCase().trim() + tUser.getSurname().toLowerCase().trim();
                let firstTU = nameDic[userKey];
                if (firstTU) {
                    callbackTUserPair([tUser, firstTU])
                } else {
                    nameDic[userKey] = tUser;
                }
            })
        })
    };
    this.addUserToGroup = function(aGroupedResults, key, tournamentUser) {
        let groupedTUList = aGroupedResults[key];
        if (groupedTUList) {
            this.pushUserCupSorted(groupedTUList, tournamentUser);
        } else {
            let newList = [];
            newList.push(tournamentUser);
            aGroupedResults[key] = newList;
        }
    };
    this.pushUserCupSorted = function(groupedTUList, tournamentUser) {
        let added = false;
        let index = groupedTUList.length - 1;
        while (index >= 0 && !added) {
            if (tournamentUser.getCupPoints() < groupedTUList[index].getCupPoints()) {
                // add last
                groupedTUList.splice(index + 1, 0, tournamentUser);
                added = true;
            } else if (tournamentUser.getCupPoints() === groupedTUList[index].getCupPoints()) {
                // check for lower position counter - lower means better
                if (tournamentUser.getKillValue() > groupedTUList[index].getKillValue()) {
                    groupedTUList.splice(index + 1, 0, tournamentUser);
                    added = true;
                } else if (tournamentUser.getKillValue() === groupedTUList[index].getKillValue()) {
                    if (tournamentUser.getFinalKillValue() < groupedTUList[index].getFinalKillValue()) {
                        groupedTUList.splice(index + 1, 0, tournamentUser);
                        added = true;
                    }
                }
            }
            index--;
        }
        if (!added) {
            groupedTUList.splice(0, 0, tournamentUser);
        }
    };
    this.checkForGroup = function (aGroups, group) {
        let found = false;
        for(let i = 0; i < aGroups.length; i++) {
            if (aGroups[i].id == group.id) {
                found = true;
                break;
            }
        }
        if (!found) {
            aGroups.push(group);
        }
    };
    this.getGroupRoundAmount = function (key) {
        let groupedRoundSize = this.roundSize[key];
        if (groupedRoundSize) {
            return groupedRoundSize;
        }
        return 1;
    };
    this.getFinalGroupRoundAmount = function () {
        return 0;
    };
    this.getTournamentUserSavingList = function (tournamentID) {
        let resultList = [];
        // iterate through groupedResult
        Object.keys(this.groupedResults).map(key => {
            let position = 1;
            let groupedTUList = this.groupedResults[key];
            // iterate through list of one group
            for (let i = 0; i < groupedTUList.length; i++) {
                let lTU = groupedTUList[i];
                let nTu = new TournamentUser();
                nTu.setStatus(TournamentUser.prototype.status.finished);
                nTu.setTournamentID(tournamentID);
                nTu.setTournamentStringID(tournamentID.id);
                nTu.setLicenseNumber(lTU.getLicenseNumber());
                nTu.setLicenseUnionCode(lTU.getLicenseUnionCode());
                nTu.setPlayerEmail(lTU.getPlayerEmail());
                if (lTU.getUserID()) {
                    nTu.setUserID(lTU.getUserID());
                }
                nTu.setName(lTU.getName());
                nTu.setSurname(lTU.getSurname());
                nTu.setSex(lTU.getSex());
                nTu.setUnion(lTU.getUnion());
                nTu.setTournamentConfigAge(lTU.getTournamentConfigAge());
                nTu.setTournamentConfigBow(lTU.getTournamentConfigBow());
                nTu.setCupPoints(lTU.getCupPoints());
                nTu.setAllCupPoints(lTU.getAllCupPoints());
                nTu.setResultPosition(position);
                position++;
                nTu.setSumPoints(lTU.getCupPoints());
                nTu.setKillValue(0 - lTU.getKillValue());
                resultList.push(nTu);
            }
        });
        return resultList;
    }

}

module.exports = TournamentUserCupResultDic;