const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert} = require("react-bootstrap");

const Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n");

const {BaseContext} = require("context/BaseContext");

const tournamentStore = require("stores/TournamentManagerStore"),
    tournamentMgmtActions = require("actions/TournamentManagerActions"),
    notificationActions = require("components/notification/NotificationActions");

class TournamentMMLoader extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.state = {
            tournamentStore: tournamentStore.getState(),
        }
    }
    componentDidMount() {
        const { user, setTournamentIdForMenu } = this.context;
        tournamentStore.listen(this.handleTournamentChange);
        notificationActions.reset();
        tournamentMgmtActions.showDetails(this.props.tournamentId, user, this.props.adminCheck);
        if (this.props.showTournament) {
            setTournamentIdForMenu(this.props.tournamentId)
        }
    }
    componentWillUnmount() {
        const { setTournamentIdForMenu } = this.context;
        setTournamentIdForMenu(null)
        tournamentStore.unlisten(this.handleTournamentChange);
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
    }
    render() {
        const { user } = this.context;
        if (this.state.tournamentStore.loading == true) {
            return (<Grid>
                <Row>
                    <Col lg={12}><Loading/></Col>
                </Row>
            </Grid>)
        } else if(this.state.tournamentStore.invalidUser == true) {
            return (<Grid>
                <Row>
                    <Col lg={12}>
                        <Alert bsStyle="danger"><I18n code="tournament.manager.invalidUser"/></Alert>
                    </Col>
                </Row>
            </Grid>)
        } else if (this.state.tournamentStore.tournamentDetail && this.state.tournamentStore.tournamentDetail.isDeleted()) {
            return (<Grid>
                <Row>
                    <Col lg={12}>
                        <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                    </Col>
                </Row>
            </Grid>)
        }
        return <Grid>
            {React.cloneElement(this.props.children,
                { tournament: this.state.tournamentStore.tournamentDetail,
                    user: user,
                    runningTRounds: this.state.tournamentStore.runningTournamentRounds,
                    tournamentRoundMap: this.state.tournamentStore.tournamentRoundMap,
                    tournamentSlots: this.state.tournamentStore.tournamentRegSlots,
                    childTournaments: this.state.tournamentStore.childTournaments,
                    tChangeLogs: this.state.tournamentStore.tChangeLogs
                } )}
        </Grid>
    }
}
TournamentMMLoader.propTypes = {
    tournamentId:PropTypes.string.isRequired,
    showTournament: PropTypes.bool,
    adminCheck: PropTypes.bool
};
TournamentMMLoader.defaultProps = {
    showTournament: true
};
TournamentMMLoader.contextType = BaseContext;
module.exports = TournamentMMLoader;
