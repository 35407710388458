const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Button, Label, Panel, PageHeader} = require("react-bootstrap");
const ConfigKeys = require("ConfigKeys");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ListUnstyled = require("components/widgets/ListUnstyled"),
    LinkButton = require("components/links/LinkButton"),
    Loading = require("components/widgets/Loading"),
    ClubInvoiceTable = require("components/clubaccounting/ClubInvoiceTable"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    ValidInput = require("components/form/ValidInput");

const UserAboInfo = require("components/profile/UserAboInfo");
const {BaseContext} = require("context/BaseContext");

const ManagementDetail = require("components/business/ManagementDetail"),
    PaymentTargetDetail = require("components/business/PaymentTargetDetail"),
    AdditionalEditorModal = require("components/modals/AdditionalEditorModal"),
    UserImageHandler = require("components/profile/UserImageHandler"),
    ProfileLicensePanel = require("components/profile/ProfileLicensePanel"),
    AddressModal = require("components/modals/AddressModal"),
    ChangePasswordModal = require("components/modals/ChangePasswordModal"),
    messages = require("i18n/messages");

const userActions = require("actions/UserActions"),
    userStore = require("stores/UserStore"),
    notificationActions = require("components/notification/NotificationActions"),
    accountStore = require("stores/AccountStore"),
    accountActions = require("actions/AccountActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            accountStore: accountStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.account");
        userStore.listen(this.handleUserChange);
        accountStore.listen(this.handleAccountChange);
        notificationActions.reset();
        accountActions.queryMainEditorManagement(this.state.userStore.user);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        accountStore.unlisten(this.handleAccountChange);
    }
    handleAccountChange(state) {
        this.setState({accountStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    render() {
        const {user, userAboLoading, userDetailLoading} = this.state.userStore
        return (
            <Grid>
                <Row>
                    <Col xs={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/account" code="header.nav.account" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                {user == null ?
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="danger">
                                <FontAwesome icon="exclamation-circle"/>
                                <I18n code="tournament.register.description.noaccount"/>
                            </Alert>
                        </Col>
                    </Row>
                    :
                    <AccountHierarchy userAboLoading={userAboLoading} userDetailLoading={userDetailLoading}
                                     managements={this.state.accountStore.managements} />
                }
            </Grid>

        )
    }
}
const AccountHierarchy= ({userDetailLoading, userAboLoading, managements}) => {
    const { user, userInfoState:{userDetail, userAbos, userFeatures}} = React.useContext(BaseContext);
    return (<React.Fragment>
        {
            userDetailLoading === true ?
                <Loading />
                :
                <AccountInfo
                    user={user}
                    userDetail={userDetail}/>
        }
        <hr/>
        <Row>
            <Col xs={12}>
                <h2><I18n code="profile.abo.header"/></h2>
                <ErrorBoundary>
                    <UserAboInfo
                        user={user}
                        userAbos={userAbos}
                        userFeatures={userFeatures}
                        userAboLoading={userAboLoading}/>
                </ErrorBoundary>
            </Col>
        </Row>
        <hr/>
        {managements.length <= 0 ? null :
            <BusinessAccountInfo managements={managements }/>}
    </React.Fragment>)
}
const BusinessAccountInfo = ({managements}) => {
    return (<React.Fragment>
        {
            managements.map(function (management) {
                return (<BusinessManagementInfo key={management.id} management={management}/>)
            })
        }
    </React.Fragment>);
};
BusinessAccountInfo.propTypes = {
    managements:PropTypes.array.isRequired
};
const BusinessManagementInfo = ({management}) => {
    function updateAddress(address) {
        let eManagement = management;
        eManagement.getBusinessPartnerID().setAddressID(address);
        accountActions.updateManagement(eManagement)
    }
    const businessPartner = management.getBusinessPartnerID()
    return (<ErrorBoundary>
        <Row>
            <Col xs={12}>
                <PageHeader><I18n code="profile.account.business.header"/>: {management.getName()}</PageHeader>
            </Col>
        </Row>
        <Row>
            <Col sm={4}>
                <ManagementDetail management={management}/>
                {
                    businessPartner != null ? <AddressModal address={businessPartner.getAddressID()}
                                                            businessMode={true} buttonStyle="success"
                                                            handleAddressSaved={updateAddress}/> : null
                }
                <AdditionalEditorModal objectType={"Management"} object={management} titleCode={"modal.addEditor.header"}/>
            </Col>
            <Col sm={4}>
                <h4>{messages.get("profile.account.business.paymentTarget.header")}</h4>
                {
                    businessPartner != null ? <PaymentTargetDetail paymentTarget={businessPartner.getPaymentTargetID()}/> : null
                }
            </Col>
            <Col sm={4}>
                <h4>{messages.get("management.feature.header")}</h4>
                <p><strong>{messages.get("management.feature.startdate")}:</strong>{management.getAboStartDateReadable()}</p>
                <p><strong>{messages.get("management.feature.enddate")}:</strong>{management.getAboEndDateReadable()}</p>
                <ListUnstyled>
                    {management.getFeatures().map(f => {
                        return <li key={"mf" + f}><h4><Label><I18n code={"management.feature." + f}/></Label></h4></li>
                    })}
                </ListUnstyled>
                <LinkButton label="management.feature.yearsub.renew" disabled={!management.isAboFilled()}
                            href={linkUtil.getLink("club/" + ConfigKeys.SKillBoardClubId + "/aboinvoice/" + management.id)}/>
            </Col>
            <Col xs={12}>
                <h3><I18n code="header.nav.invoices"/></h3>
                <ClubInvoiceTable invoices={management.getClubInvoiceIDs()}/>
            </Col>
        </Row>
    </ErrorBoundary>);
}
BusinessManagementInfo.propTypes = {
    management:PropTypes.object.isRequired
};

const AccountInfo = ({user, userDetail}) => {
    function createUserDetail() {
        userActions.createEmptyUserDetail(user, userDetail);
    }
    function userDetailUpdated(userDetail) {
        userActions.updateUserDetail(userDetail)
    }
    return (<React.Fragment>
        <Row>
            <Col xs={12}>
                <PageHeader><FontAwesome icon="user"><I18n code="profile.account.user.header"/>: {user.getUsername()}</FontAwesome></PageHeader>
            </Col>
        </Row>
        <Row>
            <Col sm={4}>
                <AccountUser user={user}/>
            </Col>
            <Col sm={4}>
                <Panel>
                    <Panel.Heading>
                        <h4><I18n code="profile.account.user.data.header"/></h4>
                    </Panel.Heading>
                    <Panel.Body>
                        {userDetail != null && userDetail.getAddressID() != null ?
                            <AccountUserDetails userDetail={userDetail}/>
                            :
                            <Button bsStyle="success" onClick={createUserDetail}>
                                <FontAwesome icon="plus"/>{messages.get("profile.account.user.createDetail")}
                            </Button>
                        }
                    </Panel.Body>
                </Panel>
            </Col>
            {
                userDetail != null ? <Col sm={4}>
                    <Panel>
                        <Panel.Heading>
                            <h4><I18n code="profile.account.user.image"/></h4>
                        </Panel.Heading>
                        <Panel.Body>
                            <UserImageHandler userDetail={userDetail} allowEdit={true} onAfterChange={userDetailUpdated}/>
                        </Panel.Body>
                    </Panel>
                </Col> : null
            }
            <Col sm={12}>
                <ProfileLicensePanel userDetail={userDetail}/>
            </Col>
        </Row>
    </React.Fragment>);
};
AccountInfo.propTypes = {
    user:PropTypes.object.isRequired,
    userDetail:PropTypes.object
};
const AccountUser = ({user}) => {
    function pressedSaveUsername(text, viElement) {
        let newUsername = text.trim();
        let oldUsername = user.getUsername()
        console.log("Change user name from (" + oldUsername + ") to (" + newUsername + ")", viElement.isValid());
        if (newUsername !== oldUsername) {
            console.log("change to " + newUsername);
            const params = {userID: user.id, username: newUsername};
            userActions.saveUser(user, params);
        }
    }
    function pressedSaveEmail(text, viElement) {
        let newMail = text.trim();
        let oldMail = user.getEmail()
        console.log("Change E-mail from (" + oldMail + ") to (" + newMail + ")", viElement.isValid());
        if (newMail !== oldMail) {
            console.log("change to " + newMail);
            const params = {userID: user.id, email: newMail, emailVerified: false};
            userActions.saveUser(user, params);
        }
    }
    return <Panel>
            <Panel.Heading>
                <h4><I18n code="profile.account.user.login.header"/></h4>
            </Panel.Heading>
            <Panel.Body>
                <ValidInput label={<I18n code="modal.login.username"/>}
                            valid={{maxLength: 50, check:['isRequired']}}
                            placeholder={messages.get("modal.login.username")}
                            savePressed={(text, viElement) => pressedSaveUsername(text, viElement)}
                            default={user.getUsername()}/>
                <ValidInput label={<FontAwesome icon="envelope"><I18n code="modal.login.email"/></FontAwesome>}
                            valid={{maxLength: 50, check:['isRequired','isMail']}}
                            placeholder={messages.get("address.mail")}
                            savePressed={(text, viElement) => pressedSaveEmail(text, viElement)}
                            default={user.getEmail()}/>
                <br/>
                <ChangePasswordModal user={user}/><br/>
            </Panel.Body>
        </Panel>;
}
AccountUser.propTypes = {
    user:PropTypes.object.isRequired
};
const AccountUserDetails = ({userDetail}) => {
    function updateAddress(eAddress) {
        userActions.renameUserDetail(userDetail, eAddress.getName(), eAddress.getSurname());
    }
    const address = userDetail.getAddressID();
    if (address == null) {
        return null;
    } else {
        return (
            <div>
                <dl>
                    <dt className="size-140"><I18n code="tournament.register.name"/>:&nbsp;</dt>
                    <dd>{address.getName()}</dd>
                    <dt className="size-140"><I18n code="tournament.register.surname"/>:&nbsp;</dt>
                    <dd>{address.getSurname()}</dd>
                    <dt className="size-140"><I18n code="address.street"/>:&nbsp;</dt>
                    <dd>{address.getStreet()}</dd>
                    <dt className="size-140"><I18n code="address.place"/>:&nbsp;</dt>
                    <dd>{address.getZipPlace()}</dd>
                    <dt className="size-140"><I18n code="address.phone"/>:&nbsp;</dt>
                    <dd>{address.getPhone()}</dd>
                </dl>
                <AddressModal address={address} businessMode={false} buttonStyle="success"
                              handleAddressSaved={updateAddress}/>
            </div>);
    }
}
AccountUserDetails.propTypes = {
    userDetail:PropTypes.object.isRequired
};
module.exports = AccountPage;
