const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid} = require("react-bootstrap");
const {BaseContext} = require("context/BaseContext");

const Title = require("components/widgets/Title");
const langDE = require("i18n/dePolicy"),
    langEN = require("i18n/enPolicy"),
    dIterator = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

const messages = require("i18n/messages");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const PolicyPage = () => {
    const { languageState } = React.useContext(BaseContext);
    React.useEffect(() => {
        document.title = messages.get("header.nav.policy");
    })
    const dict = languageState.language === "en" ? langEN : langDE;
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/policy" code="header.nav.policy" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Title code="header.nav.policy"/>
            <PolicyArea dict={dict} />
        </Grid>)
}
const PolicyArea = ({dict}) => {
    return (
        <Row>
            <Col xs={12}>
                <p>{dict["description"]}</p>
            </Col>
            {
                dIterator.map(itNumber=> {
                    return (<Col xs={12}>
                        <h3>{dict["header"+itNumber]}</h3>
                        <p><div dangerouslySetInnerHTML={{__html: dict["body"+itNumber]}}></div></p>
                    </Col>)
                })
            }

        </Row>)
};
PolicyArea.propTypes = {
    dict: PropTypes.object.isRequired
};
module.exports = PolicyPage;
