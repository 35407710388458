const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert, Image} = require("react-bootstrap");
const {BaseContext} = require("context/BaseContext");

const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    Title = require("components/widgets/Title"),
    EventPlayersTable = require("components/profile/EventPlayersTable");

const messages = require("i18n/messages");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const eventActions = require("actions/EventActions");

const SmartTournamentPage = ({eventID}) => {
    const [eventPlayers, setEventPlayers] = React.useState(null);
    const { languageState } = React.useContext(BaseContext);
    React.useEffect(() => {
        document.title = messages.get("header.nav.smarttournament");
    })
    React.useEffect(() => {
        if (eventID != null) {
            eventActions.queryEventPlayer4SmartTournament.defer(eventID, eventPlayers => {
                setEventPlayers(eventPlayers);
            });
        }
    }, []) // run only once
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/smarttournament" code="header.nav.smarttournament" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Title code="header.nav.smarttournament"/>
            <SmartTournamentTable eventPlayers={eventPlayers} />
            <Row>
                <Col sm={3}>
                    <Image responsive src={languageState.imageDirPrefix + "/img/skillboard/smartevent_start.png"}/>
                </Col>
                <Col sm={6}>
                    {<I18nHtml code="smarttournament.text.html"/>}
                </Col>
                <Col sm={3}>
                    <Image responsive src={languageState.imageDirPrefix + "/img/skillboard/smartevent_code.png"}/>
                </Col>
            </Row>
        </Grid>)
}
SmartTournamentPage.propTypes = {
    eventID: PropTypes.string
};
const SmartTournamentTable = ({eventPlayers}) => {
    if (eventPlayers != null && eventPlayers.length > 0) {
        var event = eventPlayers[0].getEventID();
        var eventName = event.getDate() + " - " + event.getName();
        return (
            <Row>
                <Col xs={12}>
                    <br/>
                    <h3>{eventName}</h3>
                    <EventPlayersTable eventPlayers={eventPlayers} adminPage={false} />
                    <Alert><I18n code="smarttournament.text.alert" /></Alert>
                    <br/><hr/>
                </Col>
            </Row>)
    } else {
        return null;
    }
};
module.exports = SmartTournamentPage;
