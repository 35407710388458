const Alt = require("AltInit");
const actions = require("actions/LanguageActions"),
    cookieUtil = require("util/cookieUtil");

let defaultLanguage = "de";

function LanguageStore() {
    var cookie = cookieUtil.read();
    if (cookie.language) {
        defaultLanguage = cookie.language;
    }
    this.displayName = "LanguageStore";
    this.language = defaultLanguage;
    this.userParams = null;
    this.bindListeners({
        updateLanguage: actions.switchLanguage,
        updateUserParams: actions.updateUserParams
    });
}
LanguageStore.prototype.updateUserParams = function(params) {
    this.userParams = params;
};

LanguageStore.prototype.updateLanguage = function (language) {
    this.setState({
        language: language,
    });
};
module.exports = Alt.createStore(LanguageStore);
