var langDE = require("i18n/de");
var langEN = require("i18n/en");
var languageStore = require("stores/LanguageStore");

var translationDic = langDE;

languageStore.listen(function (state) {
    switch (state.language) {
        case "de":
            translationDic = langDE;
            break;
        case "en":
            translationDic = langEN;
            break;
        default:
            translationDic = langEN;
            break;
    }
});

var messages = {
    get: function (code) {
        return this.getFor(code, translationDic);
    },
    getCountTypeTranslation: function(countTypeOnlineID) {
        let message = translationDic["countType." + countTypeOnlineID];
        if (typeof message === "undefined") {
            return null;
        } else {
            return message;
        }
    },
    getFor: function (code, dic) {
        var message = dic[code];
        if (typeof message === "undefined") {
            return code;
        } else {
            return message;
        }
    }
};

/**
 * CHECK KEYS
 */
function compareLanguageDics(checkLang, checkDic, refDic) {
    var keys = "";
    Object.keys(refDic).forEach(function (code) {
        if (typeof checkDic[code] === 'undefined')
            keys += "\"" + code + "\": \"" + checkDic[code] + "\",\n";
    });
    if (keys.length > 0) {
        console.warn("MISSING KEYS IN \"" + checkLang + "\":");
        console.warn(keys);
    }
}
if (Object.keys(langDE).length != Object.keys(langEN).length) {
    console.warn("WARN: message issue!");
    compareLanguageDics("de", langDE, langEN);
    compareLanguageDics("en", langEN, langDE);
}
module.exports = messages;
